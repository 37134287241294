







import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class DotsBtn extends Vue {
  @Prop() label!: string
  @Prop() small!: boolean
}
