

























import { gsap } from 'gsap'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Dots from '@/components/atoms/btns/Dots.vue'

@Component({
  components: {
    Dots,
  },
})
export default class Anchors extends Vue {
  @Prop() collection!: any
  @Prop() state!: any
  @Prop() dynamic!: boolean

  $refs!: {
    anchors: HTMLElement[]
  }

  getClasses(index: number) {
    const { main } = this.state
    return this.dynamic
      ? [
          { active: index === main },
          { 'active-prev-prev': index === main - 2 },
          { 'active-prev': index === main - 1 },
          { 'active-next': index === main + 1 },
          { 'active-next-next': index === main + 2 },
          { 'active-hidden': index < main - 2 || index > main + 2 },
        ]
      : [{ active: index === main }]
  }

  @Watch('state.main', { deep: true, immediate: true })
  async onStateUpdate(index: number, prev: number) {
    await this.$nextTick()

    if (!this.dynamic) return

    const { anchors } = this.$refs
    const { length } = this.collection

    const anchorHeight = 28 + 16
    const containerHeight = anchorHeight * length

    const duration = prev !== undefined ? 0.4 : 0
    const direction = index - (prev || 0) < 0 ? -1 : 1

    for (let i = anchors.length; i >= 0; --i) {
      const anchor = anchors[i]
      const stagger = i * 0.04 * direction + (direction < 0 ? 0.04 * length : 0)
      const y = containerHeight * 0.5 - anchorHeight * 0.5 - anchorHeight * index
      anchor && gsap.to(anchor, { y, duration, ease: 'power2.out', delay: 0.2 + stagger })
    }
  }
}
