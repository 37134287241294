









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class RoundBtn extends Vue {
  @Prop() label!: string
  @Prop() color!: string
}
