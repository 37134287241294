
























































































import { gsap } from 'gsap'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Display from '@/components/molecules/Display.vue'
import RoundBtn from '@/components/atoms/btns/Round.vue'
import Heading from '@/components/atoms/Heading.vue'
import Resizer from '@/observable/Resizer'

@Component({
  components: {
    RoundBtn,
    Heading,
    Resizer,
    Display,
  },
})
export default class LandingWidget extends Vue {
  @Prop()
  model!: any

  @Prop()
  index!: number

  @Prop()
  active!: boolean

  @Prop()
  intersect!: boolean

  $refs!: {
    video: HTMLVideoElement
  }

  muted = true

  @Watch('muted')
  onMuted(muted: boolean) {
    this.$refs.video.muted = muted
    gsap.fromTo(this.$refs.video, { volume: 0 }, { volume: 1, duration: 1, ease: 'linear' })
  }

  @Watch('intersect')
  onIntersectChange(intersect: boolean) {
    if (!intersect && !this.muted) {
      this.muted = true
    }
  }

  getHeading(index: number) {
    return index % 2 == 0 ? 'h1' : 'h2'
  }

  resize({ width, height }: any) {
    if (this.$refs.video === undefined) return

    const ratio = width / height
    const videoRatio = 1280 / 720
    const videoWidth = ratio >= videoRatio ? width : height * videoRatio
    const videoHeight = ratio >= videoRatio ? width / videoRatio : height
    const videoX = (width - videoWidth) / 2
    const videoY = (height - videoHeight) / 2

    gsap.set(this.$refs.video, {
      height: videoHeight,
      width: videoWidth,
      left: videoX,
      top: videoY,
    })
  }
}
