import { Getter } from 'vuex-class'
import { Component } from 'vue-property-decorator'
import View from '@/mixins/View'

@Component
export default class Scene extends View {
  @Getter('products/fetched') fetched!: boolean

  async created() {
    this.$state.fetching = true
    if (this.fetched) {
      await this.$nextTick()
      this.$state.fetching = false
      this.setup()
    } else {
      await this.$store.dispatch('products/meta', this.$route.meta)
      await this.$store.dispatch('products/fetch')
      await this.$store.dispatch('products/fetchNextPack')
      this.$state.fetching = false
      this.setup()
    }
  }

  setup() {
    return
  }
}
