var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Resizer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var viewport = ref.viewport;
return [_c('Layout',{attrs:{"scrollMode":'custom'},scopedSlots:_vm._u([{key:"sticky",fn:function(){return [_c('div',{ref:"renderer",class:['renderer', { snap: _vm.snapScrollMode }]}),_c('transition',{attrs:{"css":false,"appear":true},on:{"enter":_vm.syncAnchorsIn,"leave":_vm.syncAnchorsOut}},[(_vm.snapScrollMode)?_c('Anchors',{attrs:{"collection":_vm.navigation,"state":_vm.anchors,"dynamic":true},on:{"click":_vm.gotoAnchor}}):_vm._e()],1)]},proxy:true},{key:"default",fn:function(ref){
var observer = ref.observer;
var intersected = ref.intersected;
var intersect = ref.intersect;
return [_c('div',{ref:"scrollbar",staticClass:"snap-scrollbar",attrs:{"data-scrollbar":""}},[_c('div',{staticClass:"widgets"},_vm._l((_vm.widgets),function(widget,index){return _c(((widget.theme.type) + "-widget"),{directives:[{name:"intersect",rawName:"v-intersect",value:({ observer: observer, index: index }),expression:"{ observer, index }"}],key:("widget-" + index),ref:"widgets",refInFor:true,tag:"component",class:{ snap: _vm.snapScrollMode },attrs:{"active":intersected(index) && _vm.reveal,"intersect":intersect(index) && _vm.reveal,"model":widget,"index":index}})}),1),_c('transition',{attrs:{"css":false,"appear":true},on:{"enter":_vm.syncButtonIn,"leave":_vm.syncButtonOut}},[(_vm.fetched && _vm.snapScrollMode)?_c('DomObject',{key:_vm.next,attrs:{"viewport":viewport}},[_c('div',[_c('router-link',{attrs:{"to":{
                  name: 'product-leaf',
                  params: {
                    category: _vm.category.name,
                    product: _vm.current.routes[_vm.$i18n.locale],
                  },
                },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var navigate = ref.navigate;
return [_c('Round',{attrs:{"color":_vm.theme.background,"label":_vm.current.label,"title":_vm.current.title},on:{"click":navigate}},[_c('svg',{style:({ stroke: _vm.theme.background }),attrs:{"viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M17 8l4 4m0 0l-4 4m4-4H3"}})])])]}}],null,true)})],1)]):_vm._e()],1),_c('Footer')],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }