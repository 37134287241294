



















import { Component, Vue, Prop } from 'vue-property-decorator'
import Resizer from '@/observable/Resizer'

@Component({
  components: {
    Resizer,
  },
})
export default class WebglWidget extends Vue {
  @Prop()
  model!: any

  @Prop()
  index!: number

  @Prop()
  active!: boolean

  get fullWidth() {
    return 100 * this.model.collection.length
  }
}
