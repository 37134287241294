











































































import { MediaQ } from '@/constants'
import { Component } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { enterProductLeaf } from '@/animations/Products'
import { ProductState, CategoryState } from '@/store/modules/products/types'
import Layout from '@/components/organsims/Layout.vue'
import Round from '@/components/atoms/btns/Round.vue'
import Back from '@/components/atoms/btns/Back.vue'
import Next from '@/components/atoms/btns/Next.vue'
import Resizer from '@/observable/Resizer'
import Scene from '@/mixins/Scene'
import i18n from '@/i18n'

@Component({
  components: {
    Resizer,
    Layout,
    Round,
    Back,
    Next,
  },
})
export default class ProductLeaf extends Scene {
  @Getter('products/next') next!: number
  @Getter('products/prev') prev!: number
  @Getter('products/current') current!: ProductState
  @Getter('products/category') category!: CategoryState
  @Getter('products/collection') collection!: any
  @Action('products/update') update!: (index: number) => void
  @Action('locator/setSearch') setLocatorSearch!: (search: string) => void

  styles = {
    name: { padding: `0 0 0 0` },
    text: { padding: `0 0 0 0` },
  }

  get nutritional() {
    return this.leaf.nutritional
  }

  get section() {
    return this.category.label
  }

  get leaf() {
    return this.current.leaf
  }

  get theme() {
    return this.current.theme
  }

  get path() {
    return `/${i18n.locale}`
  }

  async openStoreLocator() {
    await this.setLocatorSearch('product')
    this.$router.push({ hash: 'store-locator' })
  }

  navigateToNext() {
    const locale = this.$i18n.locale
    const length = this.collection.length - 1
    const next = this.next + 1 > length ? 0 : this.next + 1
    const {
      routes: { [locale]: product },
      category: { name: category },
    } = this.collection[next]
    this.$router
      .push({ name: 'product-leaf', params: { product, category } })
      .catch((error) => error)
  }

  scroll({ offset }: any) {
    this.$state.scrollOffset = offset.y
  }

  resize({ width, height }: any) {
    let namePaddingTop = height * 0.68,
      textPaddingLeft = 0,
      textPaddingRight = 40

    if (width >= MediaQ.MD) {
      namePaddingTop = height * 0.6
      textPaddingLeft = height * 0.05
      textPaddingRight = 40
    }

    if (width >= MediaQ.LG) {
      textPaddingLeft = height * 0.1
    }

    if (width >= MediaQ.XL) {
      textPaddingRight = 0
    }

    this.styles.name.padding = `${namePaddingTop}px 0 0 0`
    this.styles.text.padding = `0 ${textPaddingRight}px 0 ${textPaddingLeft}px`
  }

  setup() {
    const element = this.$el as HTMLElement
    enterProductLeaf(element)
  }
}
