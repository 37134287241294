















































































import { gsap } from 'gsap'
import { Getter, Action, State } from 'vuex-class'
import { Component, Watch } from 'vue-property-decorator'
import {
  CategoryState,
  ProductState,
  ProductWidget,
  SceneTheme,
} from '@/store/modules/products/types'
import SmoothScrollbar from 'smooth-scrollbar'
import LandingWidget from '@/components/organsims/widgets/Landing.vue'
import GridboxWidget from '@/components/organsims/widgets/Gridbox.vue'
import CoverWidget from '@/components/organsims/widgets/Cover.vue'
import WebglWidget from '@/components/organsims/widgets/Webgl.vue'
import Anchors from '@/components/molecules/Anchors.vue'
import Footer from '@/components/organsims/Footer.vue'
import Layout from '@/components/organsims/Layout.vue'
import Round from '@/components/atoms/btns/Round.vue'
import DomObject from '@/webgl/components/DomObject'
import Resizer from '@/observable/Resizer'
import Scene from '@/mixins/Scene'

@Component({
  components: {
    LandingWidget,
    GridboxWidget,
    CoverWidget,
    WebglWidget,
    DomObject,
    Resizer,
    Anchors,
    Layout,
    Footer,
    Round,
  },
})
export default class Products extends Scene {
  @Getter('products/next') next!: number
  @Getter('products/prev') prev!: number
  @Getter('products/theme') theme!: SceneTheme
  @Getter('products/widgets') widgets!: ProductWidget
  @Getter('products/current') current!: ProductState
  @Getter('products/category') category!: CategoryState
  @Getter('products/direction') direction!: number
  @Getter('products/navigation') navigation!: any
  @Action('products/update') update!: (index: number) => void
  @Action('products/reset') reset!: () => void

  @Getter('app/menuopen') menuopen!: boolean

  @State('router') router!: any

  @Watch('menuopen')
  onMenuOpen(menuopen: boolean) {
    //if (!this.$device.mobile) return
    menuopen ? this.disposeSmoothScrollbar() : this.initSmoothScrollbar()
  }

  timeline!: gsap.core.Timeline

  scrollbar: SmoothScrollbar | null = null

  snapLanding = false

  $refs!: {
    scrollbar: HTMLElement
    renderer: HTMLElement
    trigger: HTMLElement
    widgets: any[]
  }

  anchors = {
    main: 0,
    subs: -1,
  }

  get toLeaf() {
    return !!this.router.to.meta.isLeaf
  }

  get fromLeaf() {
    return !!this.router.from.meta.isLeaf
  }

  get initialized() {
    return !!this.scrollbar || this.fromLeaf
  }

  get snapScrollMode() {
    return this.$state.scrollSnap && !this.toLeaf
  }

  @Watch('next', { immediate: true })
  onStateUpdate() {
    this.anchors.main = this.next
  }

  gotoAnchor({ main }: any) {
    const { index } = this.navigation[main]
    this.update(index)
  }

  async customScroll({ offset }: any) {
    const { top, height } = this.getBoundingBox(this.$refs.trigger)

    this.$state.scrollSnap = offset.y >= top && offset.y <= height
    if (this.$state.scrollSnap || this.$state.transition) {
      const panelOffsetTop = this.next * window.innerHeight
      const offset = top + panelOffsetTop
      this.scrollbar?.setMomentum(0, 0)
      this.scrollbar?.setPosition(0, offset)
    }

    await this.$nextTick()

    this.snapLanding = !this.$state.scrollSnap

    if (offset.y > 0 && this.$state.needsScroll) this.$state.needsScroll = false
    else if (offset.y <= 0 && !this.$state.needsScroll) this.$state.needsScroll = true
  }

  getBoundingBox(element: HTMLElement) {
    const left = 0
    const top = element.offsetTop
    const width = window.innerWidth
    const height = element.offsetHeight + top - window.innerHeight
    return { top, left, width, height }
  }

  syncButtonIn(el: HTMLElement, done: () => void) {
    if (!this.initialized) return done()

    this.$state.transition = true

    const delay = this.snapLanding ? 0 : 1.2

    const onUpdate = () => {
      this.$state.transition = this.timeline.progress() < 0.5
    }

    this.timeline = gsap
      .timeline({ delay, onUpdate: onUpdate, onComplete: done })
      .from(el.children, {
        duration: 1.2,
        opacity: 0,
        rotate: 30 * this.direction,
        scale: 1,
        y: 10 * this.direction,
        ease: 'elastic.out',
      })
  }

  syncButtonOut(el: HTMLElement, done: () => void) {
    const onComplete = () => {
      this.clear()
      done()
    }

    const delay = this.toLeaf ? 0 : 0.3
    const direction = this.toLeaf ? 1 : this.direction

    gsap.timeline({ delay, onComplete }).to(el.children, {
      duration: 0.4,
      opacity: 0,
      rotate: -30 * direction,
      scale: 1,
      y: -10 * this.direction,
      ease: 'power2.inOut',
    })
  }

  syncAnchorsIn(el: HTMLElement, done: () => void) {
    const selector = gsap.utils.selector(el)
    gsap.timeline({ onComplete: done, delay: 0.3 }).from(selector('.dots-btn'), {
      opacity: 0,
      y: 30,
      duration: 0.4,
      stagger: 0.08,
      ease: 'power2.out',
    })
  }

  syncAnchorsOut(el: HTMLElement, done: () => void) {
    const selector = gsap.utils.selector(el)
    gsap.set(el, { zIndex: 0 })
    gsap.timeline({ onComplete: done }).to(selector('.dots-btn'), {
      opacity: 0,
      y: -30,
      duration: 0.4,
      stagger: 0.06,
      ease: 'power2.out',
    })
  }

  initSmoothScrollbar() {
    this.scrollbar = SmoothScrollbar.init(this.$refs.scrollbar, {
      delegateTo: document,
      damping: 0.2,
    })
    this.scrollbar.addListener(this.customScroll)
  }

  disposeSmoothScrollbar() {
    if (this.scrollbar) {
      this.scrollbar.removeListener(this.customScroll)
      this.scrollbar.destroy()
    }
  }

  setup() {
    this.initSmoothScrollbar()

    this.$refs.trigger = this.$refs.widgets.find(({ $refs }) => $refs.trigger).$el
    this.$refs.renderer.appendChild(this.gl.renderer.css.domElement)

    if (this.fromLeaf) {
      const { top } = this.getBoundingBox(this.$refs.trigger)
      this.customScroll({ offset: { x: 0, y: top } })
    } else {
      this.$state.scrollSnap = false
      this.reset()
      this.clear()
    }
  }

  clear() {
    const { css: scene } = this.gl.scene
    const limit = !this.$state.scrollSnap || this.toLeaf ? 0 : 1
    while (scene.children.length > limit) scene.remove(scene.children[0])
  }

  beforeDestroy() {
    this.disposeSmoothScrollbar()
  }
}
