import { Object3D } from 'three'
import { MediaQ } from '@/constants'
import { css } from '@/webgl/hooks/utils'
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer'
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class DomObject extends Vue {
  @Prop()
  viewport!: any

  object!: Object3D

  @Watch('viewport', { immediate: true, deep: true })
  resize({ width }: any) {
    const { scale } = this.gl.scene

    const x = MediaQ.MD > width ? 0 : 0
    const y = MediaQ.MD > width ? -1.02 : -0.7
    const z = MediaQ.MD > width ? 0.6 : 0.6

    /* const x = MediaQ.MD > width ? 0 : -0.5
    const y = MediaQ.MD > width ? -1.02 : -0.4
    const z = MediaQ.MD > width ? 0.6 : 0.6 */

    this.object && this.object.position.set(x / scale, y / scale, z / scale)
  }

  mounted() {
    const element = this.$el as HTMLElement
    const CSSObject = 'css3d' === css() ? CSS3DObject : CSS2DObject

    this.object = new CSSObject(element)
    this.gl.scene.css.add(this.object)

    this.resize(this.viewport)
  }

  render() {
    return this.$slots.default
  }
}
