


















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class BackBtn extends Vue {
  @Prop() section!: string
  @Prop() href!: string
}
